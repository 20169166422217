import React from "react"
import { useState, useEffect } from "reactn"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import HybridLink from "Partials/HybridLink/HybridLink"
import MenuLogoSvg from "static/assets/logos/moby-digg.svg"
import MenuIconSvg from "static/assets/ui/menu.svg"
import MenuCloseIconSvg from "static/assets/ui/x.svg"
import NavigationOverlay from "Layout/Navigation/NavigationOverlay/NavigationOverlay"
import "./MainNavigation.scss"

const MainNavigation = ({
  location,
  locale,
  translations,
  hideMenu
}) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allWpMenu {
        edges {
          node {
            name
            menuItems {
              nodes {
                id
                parentId
                label
                url
              }
            }
          }
        }
      }
    }
  `);
  const element = typeof window !== "undefined" ? window.document.body : null
  const className = "modal-open"

  const [mainMenu, setMainMenu] = useState([]);
  const [menuOverlay, setMenuOverlay] = useState(false)
  const [scrollDir, setScrollDir] = useState("")
  const [matchesTabletMobile, setMatchesTabletMobile] = useState(false);

  const updateMatchesTabletMobile = e => setMatchesTabletMobile(e.matches);

  useEffect(() => {
    setMatchesTabletMobile(window.matchMedia("(max-width: 1024px)").matches); //$tablet-landscape

    window
    //$tablet-landscape
    .matchMedia("(max-width: 1024px)")
    .addEventListener('change', updateMatchesTabletMobile);

    return () => {
      window
      //$tablet-landscape
      .matchMedia("(max-width: 1024px)")
      .removeEventListener('change', updateMatchesTabletMobile);
    }
  }, []);

  // Close menu overlay if change from tablet to desktop
  useEffect(() => {
    if(!matchesTabletMobile) {
      setMenuOverlay(false);
      element.classList.remove(className)
    }
    if(matchesTabletMobile) {
      setActiveMainMenuParent(null)
    }
    setScrollDir("")
  }, [matchesTabletMobile, element, className]);

  // create menu with sub items
  useEffect(() => {
    const { allWpMenu } = data
    const menus = allWpMenu?.edges?.filter(item => item.node?.name === `Main Navigation ${locale}`)[0]?.node.menuItems.nodes;
    const mainItems = menus.filter(menuItem => !menuItem.parentId);
    const childItems = menus.filter(menuItem => !!menuItem.parentId);

    const mainMenu = mainItems.map(menuItem => {
      let subItems = null;
      const childMenu = childItems.filter(childItem => childItem.parentId === menuItem.id);
      if(childMenu.length) {
        subItems = childMenu;
      }
      return ({
        ...menuItem,
        subItems
      })
    });
    setMainMenu(mainMenu);
  }, [data])

  // useEffect(() => {
  //   const threshold = 0
  //   let lastScrollY = window.pageYOffset
  //   let ticking = false

  //   const updateScrollDir = () => {
  //     const scrollY = window.pageYOffset

  //     if (Math.abs(scrollY - lastScrollY) < threshold) {
  //       ticking = false
  //       return
  //     }

  //     setScrollDir(scrollY > lastScrollY ? "down" : "up")
  //     lastScrollY = scrollY > 0 ? scrollY : 0
  //     ticking = false
  //   }

  //   const onScroll = () => {
  //     if (!ticking) {
  //       window.requestAnimationFrame(updateScrollDir)
  //       ticking = true
  //     }
  //   }

  //   window.addEventListener("scroll", onScroll)
  //   // console.log(scrollDir)

  //   if (menuOverlay) {
  //     element.classList.add(className)
  //   } else {
  //     element.classList.remove(className)
  //   }

  //   return function cleanup() {
  //     element.classList.remove(className)
  //     window.removeEventListener("scroll", onScroll)
  //   }
  // }, [scrollDir])

  const setMenuOverlayFn = () => {
    const overlay = !menuOverlay;
    setMenuOverlay(overlay);

    if (overlay) {
      element.classList.add(className)
    } else {
      element.classList.remove(className)
    }
  }

  const setActiveMainMenuParent = (id) => {
    const newMenu = mainMenu.map(menuItem => ({
      ...menuItem,
      isActive: menuItem.id === id ? !menuItem.isActive : false
    }));
    setMainMenu([...newMenu]);
  };

  const handleMenuClick = () => {
    setMenuOverlayFn()
    setScrollDir("")
  }

  return (
    <div>
      <div
        id="headroom"
        // className={classNames("headroom container-fluid position-fixed bg-white p-1 border-left", scrollDir === "down" ? "is-hidden" : "")}
        className={classNames("headroom container-fluid position-fixed bg-white p-1 border-left")}
      >
        <div className="container">
          <div className="row">
            <div className="navigation">
              <nav className="desktop-navigation flex-column justify-content-center">
                <ul className="primary-menu d-flex m-0">
                  <li className="menu-item-level-1">
                    <HybridLink
                      internal={'/'+(locale === 'en_GB' ? 'en/' : '')}
                      type="internal"
                      className="mb-1 mb-lg-0"
                      activeClass="is-active"
                    ><img className="logo" alt={locale === 'en_GB' ? 'Moby Digg logo' : 'Moby Digg Logo'} src={MenuLogoSvg} /></HybridLink>
                  </li>
                  {
                    !hideMenu && mainMenu?.map((menuItem) => {
                      const currentLocation = location.pathname.split("/")[1]
                      const currentSlug = menuItem.url
                      let parentActive = false

                      if (currentLocation === currentSlug) {
                        // parent (custom post type) active
                        parentActive = true
                      }

                      if(!!menuItem.subItems) {
                        return <li key={`${menuItem.id}--navigation-overlay`} className="menu-item-level-1 parent">
                          <HybridLink
                            internal={menuItem.url.split(".de")[1] || menuItem.url}
                            type="internal"
                            classes={classNames("mb-1 mb-lg-0", parentActive ? "is-active" : "")}
                            activeClass="is-active"
                            noLink={!menuItem.url || menuItem.url === '#'}>
                            <div
                              className={classNames('parent__head', menuItem.isActive && 'parent__head--active')}
                              onClick={setActiveMainMenuParent ? (e) => {
                                const currentLocation = location || window?.location;
                                if(currentLocation?.pathname?.includes(menuItem.url)) {
                                  e?.preventDefault();
                                }
                                setActiveMainMenuParent(menuItem.id);
                                } : null}>
                              {menuItem.label}
                            </div>
                          </HybridLink>
                          <div className={classNames(
                              'parent__children',
                              menuItem.isActive && 'parent__children--active',
                              scrollDir === "down" ? "parent__children--hidden-menu" : ""
                            )}
                            onClick = {(e) => {
                              const isParentChildren = String(e?.target?.classList || []).includes('parent__children');
                              if(isParentChildren) {
                                setActiveMainMenuParent(null)
                              }
                            }}>
                            <div className="parent__content">
                              {menuItem.subItems.map(subItem => {
                                const {id, url, label} = subItem;
                                return <HybridLink
                                  key={id}
                                  internal={url.split(".de")[1] || url}
                                  type="internal"
                                  classes={classNames("parent__child mb-1 mb-lg-0", parentActive ? "is-active" : "")}
                                  activeClass="is-active">
                                  <span>{label}&nbsp;&#8594;</span>
                                </HybridLink>
                              })}
                            </div>
                          </div>
                        </li>
                      }

                      return (
                        <li key={menuItem.id} className="menu-item-level-1">
                          <HybridLink
                            internal={menuItem.url.split(".de")[1] || menuItem.url}
                            type="internal"
                            classes={classNames("mb-1 mb-lg-0", parentActive ? "is-active" : "")}
                            activeClass="is-active"
                            title={menuItem.label}
                          />
                        </li>
                      )
                    })
                  }
                  {
                    !hideMenu && translations?.length > 0 && translations.map((translation) => {
                      return (
                        <li key={translation.uri} className="menu-item-level-1">
                          <HybridLink
                            internal={translation.uri}
                            type="internal"
                            isLangChange
                            setUserLang={translation.language.slug}
                            classes={classNames("mb-1 mb-lg-0 text-uppercase")}
                            activeClass="is-active"
                            title={translation.language.slug}
                          />
                        </li>
                      )
                    })
                  }
                  <li className="menu-toggle-icon-wrapper d-flex ml-auto">
                    {
                      menuOverlay === false ? (
                        <img src={MenuIconSvg} className="menu-open-icon d-block ml-auto" alt="Menu" title="Menu" onClick={handleMenuClick} />
                      ) : (
                        <img src={MenuCloseIconSvg} className="close-icon d-block ml-auto" alt="Menu" title="Menu" onClick={handleMenuClick} />
                      )
                    }
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <NavigationOverlay
        location={location}
        translations={translations}
        mainMenu={mainMenu}
        menuOpen={menuOverlay}
      />
    </div>
  )
}
export default MainNavigation
