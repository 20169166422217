import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import TextGrid from "Sections/TextGrid/TextGrid"
import ComparisonCard from "Partials/ComparisonCard/ComparisonCard"
import { useStaticQuery, graphql } from "gatsby"
import "./ComparisonOverview.scss"

const ComparisonOverview = ({
  overviews = [],
  locale,
  classes = ''
}) => {
  const data = useStaticQuery(graphql`
    query ComparisonOverviewStaticQuery {
      projects: allWpProject (
        filter: {title: { ne: "DO NOT DELETE" }}
      ) {
        edges {
          node {
            nodeType
            id
            title
            excerpt
            uri
            language {
              locale
            }
            projectCategories {
              nodes {
                name
              }
            }
            featuredImage {
              ...FeaturedImage
            }
          }
        }
      }
    }
  `);

  const localeProjects = data?.projects?.edges?.filter(
    item => item.node.nodeType === 'Project' && item.node.language?.locale === locale
  ).filter(Boolean);

  return (
    <div className={classNames("comparison-overview", classes)}>
      {
        (overviews || []).map(({textGridType, texts, criterias, features}) => (<>
          <TextGrid textGridType={textGridType} texts={texts} />
          <ComparisonCard criterias={criterias} features={features} classes={classes} localeProjects={localeProjects} />
        </>))
      }
    </div>
  )
}

ComparisonOverview.propTypes = {
  overviews: PropTypes.arrayOf(
    PropTypes.shape({
      textGridType: PropTypes.string.isRequired,
      texts: PropTypes.instanceOf(Object),
    })
  ),
  classes: PropTypes.string,
  locale: PropTypes.string
}

export default ComparisonOverview
