import React from 'react'
import {Link} from 'gatsby'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const HybridLink = props => {
  const {
    title,
    type,
    internal,
    external,
    noLink,
    hideTitle,
    target,
    classes,
    activeClass,
    children,
    isLangChange,
    setUserLang,
    key
  } = props

  if(!internal && !external) return null

  const setLangFlag = () => {
    isLangChange && localStorage.setItem("mdCustomLang", true);
    setUserLang && localStorage.setItem("mdSetUserLang", setUserLang);
  }

  // get internalRendered link / slug ready
  // source: https://stackoverflow.com/questions/6944744/javascript-get-portion-of-url-path
  let internalRendered;
  if(typeof window !== "undefined" && internal){
    const shellLink = document.createElement('a');
    shellLink.href = typeof internal === "string" && internal || typeof internal === "object" && internal.uri
    internalRendered = shellLink.pathname
  }

  let html = null

  if(type === "internal" && internalRendered && !noLink) {
    html = (
      <Link key={key} to={internalRendered} className={classnames(classes, 'link link-internal')} activeClassName={activeClass} onClick={setLangFlag}>
        {!hideTitle && title}
        {children}
      </Link>
    )
  }

  if(type === "external" && external && !noLink) {
    html = (
      <a key={key} className={classnames(classes, 'link link-external')} href={external} target={target || "_blank"} rel="noopener noreferrer">
        {!hideTitle && title}
        {children}
      </a>
    )
  }

  if(noLink) {
    html = (
      <span key={key} className={classnames(classes, 'link no-link')}>
        {!hideTitle && title}
        {children}
      </span>
    )
  }

  return html
}

HybridLink.defaultProps = {
  hideTitle: false,
  noLink: false,
  target: ""
}

HybridLink.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  internal: PropTypes.PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
  external: PropTypes.string,
  hideTitle: PropTypes.bool,
  noLink: PropTypes.bool,
  classes: PropTypes.string,
  activeClass: PropTypes.string,
  target: PropTypes.string
}

export default HybridLink;
