import React, {useState} from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames"
import "./Image.scss"

const Image = ({
  image,
  aspectRatios,
  cropImage,
  dataSwiperParallax,
  dataSwiperParallaxScale,
  classes,
  caption,
  altText,
  styles,
  useStandardImage,
  canZoom
}) => {
  if(!image) return null

  // zoomed images
  const [zoomed, setZoomed] = useState(false)
  const [unZoomedHeight, setUnZoomedHeight] = useState(0)
  const [unZoomedWidth, setUnZoomedWidth] = useState(0)
  const toggleZoom = (e) => {
    e.preventDefault()
    setUnZoomedHeight(e.target.height)
    setUnZoomedWidth(e.target.width)
    canZoom && document.body.classList.toggle('freeze')
    canZoom && setZoomed(!zoomed)
  }

  // check image object
  let imageFile
  let aspectRatio
  let src
  if(
      image && image.localFile && image.localFile.childImageSharp
      && !useStandardImage
    ){
      imageFile = getImage(image.localFile)
  } else {
    // generate regular image data
    const regularImage = {
      height: image && image.mediaDetails && image.mediaDetails.height,
      width: image && image.mediaDetails && image.mediaDetails.width,
      src: image && image.sourceUrl || image,
      aspectRatio: 0,
    }
    // aspectRatio === width / height
    regularImage.aspectRatio = regularImage.width / regularImage.height

    // return null for invalid image objects
    if(!regularImage || !regularImage.src || !regularImage.aspectRatio) return null

    // for gif and standard images
    ({aspectRatio, src} = regularImage)
    imageFile = null
    useStandardImage = true
  }

  let stylesWrapper = {}
  let stylesImage = {}

  if(cropImage) {
    if(!aspectRatios) {
      // console.log("Image--> No aspectRatios defined! Aborting crop.")
      return null
    }

    const possibleAspectRatios = aspectRatios || [0.667, 1.334]
    const aspectRatioConverted = aspectRatio < 1 ? aspectRatio + 1 : aspectRatio - 1

    const findMatchingCropping = () => {
      let smallestDiff = Math.abs(aspectRatioConverted - possibleAspectRatios[0])
      let closest = 0

      for (let i = 1; i < possibleAspectRatios.length; i += 1) {
        const currentDiff = Math.abs(aspectRatioConverted - possibleAspectRatios[i])
        if (currentDiff < smallestDiff) {
          smallestDiff = currentDiff
          closest = i
        }
      }
      return `${possibleAspectRatios[closest] * 100}%`
    }

    stylesWrapper = {
      position: "relative",
      overflow: "hidden",
      height: 0,
      paddingTop: findMatchingCropping()
    }

    stylesImage = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      minWidth: "100%",
      height: "auto",
      minHeight: "100%",
      objectFit: "cover",
      objectPosition: "50% 50%"
    }
  }

  const standardImage = (
    <img
      src={src}
      alt={altText || image.altText}
      title={altText || image.altText}
      style={{...stylesImage}}
      data-swiper-parallax={dataSwiperParallax}
      data-swiper-parallax-scale={dataSwiperParallaxScale}
    />
  )

  return <>
    <div
      className={classNames(zoomed && "d-block" || "d-none")}
      style={{
        width: `${unZoomedWidth}px`,
        height: `${unZoomedHeight}px`,
      }}
    />
    <div
      className={classNames("image-container", canZoom && "can-zoom", zoomed && "is-zoomed", classes)}
      style={{
        ...stylesWrapper,
        ...styles
      }}
      onClick={(e) => {canZoom && toggleZoom(e)}}
    >
      {useStandardImage && standardImage}
      {!useStandardImage && (
        <GatsbyImage
          image={imageFile}
          placeholder="dominantColor"
          alt={altText || image.altText}
          title={altText || image.altText}
          fadeIn
          style={{...stylesImage}}
          data-swiper-parallax={dataSwiperParallax}
          data-swiper-parallax-scale={dataSwiperParallaxScale} />
      )}
      {caption && (<div className="wp-caption-text">{caption}</div>)}
    </div>
  </>;
}

Image.defaultProps = {
  canZoom: false,
  cropImage: false,
  useStandardImage: false,
  image: null,
  dataSwiperParallax: "10%",
  dataSwiperParallaxScale: "1.2"
}

Image.propTypes = {
  aspectRatios: PropTypes.instanceOf(Array),
  cropImage: PropTypes.bool,
  classes: PropTypes.string,
  dataSwiperParallax: PropTypes.string,
  caption: PropTypes.string,
  dataSwiperParallaxScale: PropTypes.string,
  useStandardImage: PropTypes.bool,
  image: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
  altText: PropTypes.string
}

export default Image
