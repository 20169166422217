import React, {useEffect, useState, useRef} from 'react'
import PropTypes from "prop-types"
import { useForm } from 'react-hook-form'
import classNames from "classnames"
import axios from "axios"
import "./HubSpotEmailForm.scss"

const HubSpotEmailForm = ({
  locale,
  portalId,
  formId,
}) => {
  const { register, watch, handleSubmit, formState: { errors } } = useForm();

  const [submitActive, setSubmitActive] = useState(false)
  const responseRef = useRef(null)

  const submitHubspotForm = async (email) => {
    const portalIdIn = portalId
    const formIdIn = formId
    const emailIn = email

    const config = { // important!
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const response = await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalIdIn}/${formIdIn}`,
      {
        portalId: portalIdIn,
        formGuid: formIdIn,
        fields: [
          {
            name: 'email',
            value: emailIn,
          }
        ],
      },
      config
    );
    return response;
  }

  const onSubmit = async (data) => {
    // alert(JSON.stringify(data));
    if(!data.emailInput || submitActive) return;
    setSubmitActive(true)
    const hubspotResponse = await submitHubspotForm(data.emailInput);
    console.log(hubspotResponse); // make sure it succeeded!

    if(hubspotResponse.status === 200) {
      // all bueno
      setSubmitActive(false)
      responseRef.current.innerHTML = hubspotResponse.data.inlineMessage
    } else {
      // show error
      setSubmitActive(false)
    }
  }
  errors.emailInput && console.log('errors', errors);

  const watchEmail = watch("emailInput", "");

  useEffect(() => {

  }, [])

  return (
      <form
        id="hubspot-email-htmlForm"
        className="hubspot-email-form-wrap d-flex"
        onSubmit={handleSubmit(onSubmit)}
        >
        <div
          className={classNames(
            "input-wrap",
            watchEmail && watchEmail.length > 0 && "hovering",
            (errors.emailInput?.type === 'required' || errors.emailInput?.type === 'pattern') && "error"
          )}
        >
          <label
            htmlFor="email-input"
            className="input-label"
          >
            {locale === "de_DE" && `E-Mail Adresse` || `Email address`}
          </label>
          <input
            id="email-input"
            type="text"
            className="input-field"
            placeholder={locale === "de_DE" && `Gib deine E-Mail Adresse ein` || `Enter your email address`}
            aria-labelledby="email-label-id"
            {...register("emailInput", {required: true, pattern: /^\S+@\S+$/i})}
            aria-invalid={errors.email ? "true" : "false"}
          />
        </div>

        <button className={classNames("input-submit", submitActive && "submit-active")} type="submit" form="hubspot-email-htmlForm" value="Submit">
          {submitActive && (
            <span className="">{locale === "de_DE" && `Sende...` || `Sending...`}</span>
          ) || (
            <span className="">{locale === "de_DE" && `Kontakt aufnehmen` || `Get in touch`}</span>
          )}
        </button>
        <div className="hubspot-response" ref={responseRef} />
      </form>

  );
}

HubSpotEmailForm.defaultProps = {
  locale: "en_GB"
}

HubSpotEmailForm.propTypes = {
  locale: PropTypes.string.isRequired
}

export default HubSpotEmailForm;
