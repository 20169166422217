import React, {useEffect, useRef} from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Vimeo from '@u-wave/react-vimeo'
import Image from "Partials/Image/Image"
import Video from "Partials/Video/Video"
import {RenderMediaLink} from "Partials/ViewLink/ViewLink"
import TextNodesUnder from 'utils/text-nodes-under'
import HubSpotEmailForm from "../HubSpotEmailForm/HubSpotEmailForm"
import "./MediaCard.scss"
import "../Scrollytelling/Scrollytelling.scss"

const MediaCard = ({ media, classes, canZoom, aspectRatios, locale }) => {
  const activeColor = '#fff';
  const color = '#86868B';
  const containerRef = useRef(null);

  const renderImage = (media) => (
    <>
      {aspectRatios === "KEEPRATIO" && (
        <Image
          image={media?.image}
          altText={media?.image?.altText}
          canZoom={canZoom}
        />
      ) || (
        <Image
          image={media?.image}
          altText={media?.image?.altText}
          canZoom={canZoom}
          cropImage
          aspectRatios={aspectRatios || [0.667]}
        />
      )}
    </>
  )

  const renderVideo = (media) => (
    <>
      {media.video && media.video.mediaItemUrl && (
        <Video
          src={media.video.mediaItemUrl}
          poster={media?.image?.sourceUrl}
          hasControls={media?.controls}
          classes={classes}
        />
      )}
    </>
  )
  const renderVimeoEmbed = (media) => (
    <>
      {media.vimeoembed && (
        <div className="vimeo-video-wrapper">
          <div className="vimeo-video-inner" classes={classes}>
              <Vimeo
                video={media.vimeoembed}
                autoplay
              />
          </div>
        </div>
      )}
    </>
  )

  // Include media of matching type
  const renderMedia = (media) => {
    switch(media.type) {
      case "isVideo":
        return renderVideo(media)
      case "isVimeoEmbed":
        return renderVimeoEmbed(media)
      case "isImage":
        return renderImage(media)
      default:
        return false
    }
  }

  const changeColor = () => {
    const element = containerRef?.current;
    if(!element) {
      return;
    }
    const height = typeof window !== "undefined" && window.innerHeight || 0;
    const halfH = height / 2;

    const textParents = TextNodesUnder(element);
    textParents.forEach(item => {
      const rect = item.getBoundingClientRect();
      const elemTop = rect.top;
      const elemHeight = rect.height;

      const activeArea = (halfH - 2 * elemHeight);
      if(
        elemTop > activeArea &&
        elemTop < halfH
        // item instanceof Node
      ) {
        item.style.color = activeColor;
      } else {
        item.style.color = color;
      }
    });
  }

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      changeColor();
    }, 100)
    window.addEventListener("scroll", changeColor);
    return () => {
      clearTimeout(currentTimeout);
      window.removeEventListener("scroll", changeColor);
    }
  }, [])

  const renderMediaCaption = (media) => {
    if(!media) {
      return null
    }
    const {caption, subcaption, scrollytelling, hubSpotEmailForm} = media
    return <>{(caption || subcaption) && (
      <>
        {scrollytelling && (
          <div className={classNames("text-scrollytelling")}>
            {caption && (
              <div
                className={classNames("text-scrollytelling__content")}
                ref={containerRef}
                dangerouslySetInnerHTML={{__html: caption}}
              />
            )}
            {hubSpotEmailForm && hubSpotEmailForm.hasHubspotEmailFormInput && hubSpotEmailForm?.portalid?.length > 0 && hubSpotEmailForm?.formid?.length > 0 && (
              <HubSpotEmailForm locale={locale} portalId={hubSpotEmailForm.portalid} formId={hubSpotEmailForm.formid} />
            )}
          </div>
        ) || (
          <div className={classNames("media-caption")}>
            {caption && (
              <figcaption
                className="flex-grow-1"
                dangerouslySetInnerHTML={{__html: caption}}
              />
            )}
            {subcaption && (
              <span
                className="figcaption"
                dangerouslySetInnerHTML={{__html: subcaption}}
              />
            )}
            {hubSpotEmailForm && hubSpotEmailForm.hasHubspotEmailFormInput && hubSpotEmailForm?.portalid?.length > 0 && hubSpotEmailForm?.formid?.length > 0 && (
              <HubSpotEmailForm locale={locale} portalId={hubSpotEmailForm.portalid} formId={hubSpotEmailForm.formid} />
            )}
          </div>
        )}
      </>
    )}</>
  }

  return (
      <div className={classNames("media-card", classes)}>
        <figure className={classNames(
              media.reverse && "flex-row-reverse",
              "media-card-figure position-relative"
            )}>
          <div className="media-card__media mt-0">
            {renderMedia(media)}
          </div>
          {((media.caption && media.caption.length > 0) || (media.subcaption && media.subcaption.length > 0)) && (
            <div
              className={classNames(
                "media-card__description",
                media.captionOverlap && "position-absolute m-0 p-1 p-md-2 overlap"
              )}
            >
              {renderMediaCaption(media)}
              {RenderMediaLink(media)}
            </div>
          )}
        </figure>
      </div>
  )
}

MediaCard.propTypes = {
  media: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.string
}

export default MediaCard
