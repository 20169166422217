import React from "react"
import classNames from "classnames"
import HybridLink from 'Partials/HybridLink/HybridLink.jsx'
import "./NavigationOverlay.scss"

const NavigationOverlay = ({mainMenu, menuOpen, location, translations}) => {
  return (
    <div
      id="menu-overlay"
      className={classNames(menuOpen && "is-open", "menu-overlay p-1 bg-white")}
    >
      <div id="menu-column-wrapper" className="menu-column-wrapper column-wrapper d-flex flex-column justify-content-start">
        <div className="navigation d-flex justify-content-start">
          <nav className="d-flex flex-column flex-grow-1">
            <ul className="primary-menu d-flex flex-column border-left">
              {
                mainMenu?.map((menuItem) => {
                  const currentLocation = location.pathname.split('/')[1]
                  const currentSlug = menuItem.url
                  let parentActive = false

                  if (currentLocation === currentSlug) {
                    // parent (custom post type) active
                    parentActive = true
                  }
                  if(!!menuItem.subItems) {
                    return <li key={`${menuItem.id}--navigation-overlay`} className="menu-item-level-1 parent">
                      <HybridLink
                        internal={menuItem.url}
                        noLink={!menuItem.url || menuItem.url === '#'}
                        classes={classNames(parentActive ? "is-active" : "")}
                        type="internal"
                        activeClass="is-active">
                        <div
                          className="parent__head">
                          {menuItem.label}
                        </div>
                      </HybridLink>

                      <div className={classNames('parent__children', 'parent__children--active')}>
                        {menuItem.subItems.map(subItem => {
                          const {id, url, label} = subItem;
                          return <HybridLink
                            key={id}
                            internal={url.split(".de")[1] || url}
                            type="internal"
                            classes={classNames("parent__child", parentActive ? "is-active" : "")}
                            activeClass="is-active">
                            <span>{label}&nbsp;&#8599;</span>
                          </HybridLink>
                        })}
                      </div>
                    </li>
                  }

                    return (
                      <li key={`${menuItem.id}--navigation-overlay`} className="menu-item-level-1">
                        <HybridLink
                          internal={menuItem.url.split(".de")[1] || menuItem.url}
                          type="internal"
                          classes={classNames("mb-1 mb-lg-0", parentActive ? "is-active" : "")}
                          activeClass="is-active"
                          title={menuItem.label}
                        />
                      </li>
                    )
                  })
                }
            </ul>
            <ul className="language-change border-left">
              {
                  translations?.length > 0 && translations.map((translation) => {
                    return (
                      <li key={translation.uri} className="menu-item-level-1">
                        <HybridLink
                          internal={translation.uri}
                          type="internal"
                          classes={classNames("mb-1 mb-lg-0 text-uppercase")}
                          activeClass="is-active"
                          title={translation.language.slug}
                        />
                      </li>
                    )
                  })
                }
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default NavigationOverlay
