import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import PropTypes from "prop-types"
import PostCard from "Partials/PostCard/PostCard"
import "./PostOverview.scss"

const PostOverview = ({ properties, curatedPosts, location, locale, categories, postId }) => {
  const data = useStaticQuery(graphql`
    query PostOverviewStaticQuery {
      projects: allWpProject (
        filter: {title: { ne: "DO NOT DELETE" }}
      ) {
        edges {
          node {
            nodeType
            id
            title
            excerpt
            uri
            language {
              locale
            }
            projectCategories {
              nodes {
                name
              }
            }
            featuredImage {
              ...FeaturedImage
            }
          }
        }
      }
      pages: allWpPage (
        filter: {title: { ne: "DO NOT DELETE" }}
      ) {
        edges {
          node {
            nodeType
            id
            title
            # excerpt
            uri
            language {
              locale
            }
            # projectCategories {
            #   nodes {
            #     name
            #   }
            # }
            featuredImage {
              ...FeaturedImage
            }
          }
        }
      }
    }
  `)


  const { projects, pages } = data
  const { type, scope, layout, filter } = properties

  // Collect all post types
  const postTypes = [
    projects,
    pages
  ]

  // Filter the post type, language and the currently displayed post by Id
  let posts = postTypes.map(postType => {
    return postType.edges.filter(item => `is${item.node.nodeType}` === type && item.node.language?.locale === locale && item.node.id !== postId)
  }).filter(el => { return el != null && el != '' })[0]

  // Filter for selected posts only
  if(scope === "isSelection") {
    posts = curatedPosts !== null ? curatedPosts.map(curatedPost => {
      return posts?.filter(post => ((post.node.id === curatedPost.project?.id) || (post.node.id === curatedPost.page?.id)))[0]
    }).filter(el => { return el != null && el != '' }) : {}
  }

  // Search for posts which have at least one similar category name to the currently displayed post.
  if(scope === "isRelated") {
    const relatedPosts = posts?.map(post => {
      // Create a simple array, which only holds the categories of the respective post
      const relatedPostCats =
        post.node?.projectCategories?.nodes.map(item => item.name) ||
        post.node?.jobCategories?.nodes.map(item => item.name)

      // Compare the categories of the currently displayed post (categories) and the related posts' categories (relatedPostCats)
      for (let i = 0; i < categories.length; i += 1){
        for (let z = 0; z < relatedPostCats.length; z += 1){
          if(categories[i] === relatedPostCats[z]) return post
        }
      }
      return null
      // Filter the returned array for available posts, therefore exclude null values in the array
    }).filter(item => item)

    // Only display a max. amount of posts
    const maxItems = 3
    posts = relatedPosts !== undefined ?
      relatedPosts.slice(0, relatedPosts.length > maxItems ? maxItems : relatedPosts.length) : []
  }

  const [filteredPosts, setFilteredPosts] = useState(posts)
  const [filterTypeSelected, setFilterTypeSelected] = useState("")

  // Get all unique type taxonomy options for projects to use for generating the filter
  const typesOptionsTemp = new Set()
  posts?.length > 0 && posts.map(item => {
    const types =
      item.node?.projectCategories?.nodes ||
      item.node?.jobCategories?.nodes
    types?.map(type => {
      return typesOptionsTemp.add(type.name.toString().toLowerCase())
    })
  })
  const typesOptions = Array.from(typesOptionsTemp).sort()

  const filterItems = () => {
    let filteredPostsArray

    if(filterTypeSelected) {
      filteredPostsArray = posts.filter(post =>
        post.node.projectCategories?.nodes.some(item => item.name.toString().toLowerCase() === filterTypeSelected.toLowerCase()) ||
        post.node.jobCategories?.nodes.some(item => item.name.toString().toLowerCase() === filterTypeSelected.toLowerCase())
      )
    } else {
      filteredPostsArray = posts
    }
    setFilteredPosts(filteredPostsArray)
  }

  useEffect(() => {
    filterItems()
  }, [filterTypeSelected])

  return (
    posts?.length > 0 ? (
      <div className="section post-overview-section">
        <div className="container">
          {filter && (
            <div className="row post-filter pb-2">
              <nav>
                <ul className="d-flex flex-wrap m-0 p-0">
                  <li
                    onClick={() => setFilterTypeSelected("")}
                    className={classNames(filterTypeSelected === "" && "is-active")}
                  >
                    {locale === "de_DE" && "Alle" || locale === "en_GB" && "All"}
                  </li>
                  {typesOptions.map(option => (
                    <li
                      key={option}
                      className={classNames(filterTypeSelected === option && "is-active")}
                      onClick={() => setFilterTypeSelected(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          )}
          {filteredPosts.length > 0 && (
            <div className="row post-items-grid">
              {filteredPosts.map(post => (
                <div
                  key={post.node.id}
                  className={classNames("post-grid-item",
                    layout === "isSingleColumn" && "single-column" ||
                    layout === "isDoubleColumn" && "double-column" ||
                    layout === "isThreefoldColumn" && "threefold-column"
                  )}
                >
                  <PostCard
                    image={post?.node?.featuredImage?.node || null}
                    caption={post.node.title}
                    subCaption={post.node.excerpt}
                    linkTarget={post.node.uri}
                    aspectRatios={[0.667]}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    ) : null
  )
}

PostOverview.defaultProps = {
  properties: {},
  posts: {},
  projects: {},
}

PostOverview.propTypes = {
  properties: PropTypes.instanceOf(Object),
  posts: PropTypes.instanceOf(Object),
  projects: PropTypes.instanceOf(Object),
  postId: PropTypes.string.isRequired
}

export default PostOverview
