import React from "react"
import PropTypes from "prop-types"
import { MapToComponents } from "react-map-to-components"
import AccordionList from "Sections/AccordionList/AccordionList"
import MediaGrid from "Sections/MediaGrid/MediaGrid"
import MediaSlider from "Sections/MediaSlider/MediaSlider"
import PostOverview from "Sections/PostOverview/PostOverview"
import TextGrid from "Sections/TextGrid/TextGrid"
import HubSpotForm from "Sections/HubSpotForm/HubSpotForm"
import HeroOpenerImage from "Sections/HeroOpenerImage/HeroOpenerImage"
import MediaGridScrollytelling from "Sections/MediaGridScrollytelling/MediaGridScrollytelling"
import ComparisonOverview from "Sections/ComparisonOverview/ComparisonOverview"

const FlexibleContent = ({ content, location, locale, categories, postId }) => {
  // filter for right page and delete id afterwards
  let contentWithKeys

  if(content) {
    let counter  = 0
    const contentFiltered = content.filter(item => item && Object.keys(item).length > 1)
    contentWithKeys = Object.keys(contentFiltered).map((key) =>{
      const section = contentFiltered[key]
      // console.log("contentFiltered", contentFiltered)
      // console.log("key", key)
      // console.log("section", section)

      // generate type which fits the respective component name
      // for example from project_Flexcontent_Flex_Text to Text
      const typeFromQuery = section.fieldGroupName || ""
      const type = typeFromQuery.split("Flexcontent_Flex_")[1]
      // console.log("type", type)

      const newItem = {
        ...section,
        key: `${section.fieldGroupName}--${counter}`,
        type
      }
      counter += 1
      return newItem
    })
  }

  return (
    <>
      {
        contentWithKeys && contentWithKeys.length > 0 && (
          <MapToComponents
            getKey={section => section.key}
            getType={section => section.type}
            default={(
              <>
                {/* {console.log("FlexibleContent: There was an undefined key, thus no component.")} */}
              </>
            )}
            list={contentWithKeys}
            map={{
              AccordionList,
              MediaGrid,
              MediaSlider,
              PostOverview,
              TextGrid,
              HubSpotForm,
              HeroOpenerImage,
              MediaGridScrollytelling,
              ComparisonOverview,
            }}
            mapDataToProps={{
              AccordionList: ({ data }) => ({ ...data }),
              MediaGrid: ({ data }) => ({ ...data, locale }),
              MediaSlider: ({ data }) => ({ ...data }),
              PostOverview: ({ data }) => ({ ...data, location, locale, categories, postId }),
              TextGrid: ({ data }) => ({ ...data, locale }),
              HubSpotForm: ({ data }) => ({ ...data }),
              HeroOpenerImage: ({ data }) => ({ ...data }),
              MediaGridScrollytelling: ({ data }) => ({ ...data }),
              ComparisonOverview: ({ data }) => ({ ...data, locale }),
            }}
          />
        )
      }
    </>
  )
}

FlexibleContent.defaultProps = {
  categories: null
}

FlexibleContent.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
  categories: PropTypes.instanceOf(Object),
  locale: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired
}

export default FlexibleContent
