import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import HybridLink from "Partials/HybridLink/HybridLink"
import MediaCard from "Partials/MediaCard/MediaCard"
import "./Footer.scss"

const Footer = ({
  location,
  locale
}) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allWpMenu {
        edges {
          node {
            name
            menuItems {
              nodes {
                label
                url
              }
            }
          }
        }
      }
      globalData: wp {
        generalSettings {
          title
        }
        globalDataDe {
          footerData {
            address
            media {
              type
              image {
                ...LocalFile
              }
              video {
                ...Video
              }
            }
          }
        }
        globalDataEn {
          footerData {
            address
            media {
              type
              image {
                ...LocalFile
              }
              video {
                ...Video
              }
            }
          }
        }
      }
    }
  `)

  const { allWpMenu } = data
  const mainMenu = allWpMenu?.edges?.filter(item => item.node?.name === `Footer Navigation ${locale}`)[0]?.node.menuItems.nodes
  const socialsMenu = allWpMenu?.edges?.filter(item => item.node?.name === `Socials Navigation ${locale}`)[0]?.node.menuItems.nodes

  const { globalDataDe, globalDataEn } = data.globalData
  const globalData = locale === "de_DE" ? globalDataDe.footerData : globalDataEn.footerData
  const generalSettings = data.globalData.generalSettings

  return (
    mainMenu ? (
      <footer className="section footer-section d-flex flex-column mt-1 pt-1 pt-lg-3 bg-black">
        <div className="container d-flex flex-grow-1">
          <div className="row flex-grow-1">
            <div className="brand-logo pb-1 pb-md-0">
              <span>{generalSettings.title}</span>
            </div>
            <div className="brand-info pb-1 pb-lg-0">
              <div
                dangerouslySetInnerHTML={{ __html: globalData.address}}
              />
            </div>
            <nav className="main-nav">
              <ul className="nav-list px-0 pb-1 pb-md-0 pb-lg-3">
                {
                  mainMenu.map((menuItem) => (
                    <li key={menuItem.label} className="nav-item">
                      { ((typeof menuItem === "string" && menuItem.includes('mobydigg.de')) || (menuItem.uri && typeof menuItem === "object" && menuItem.uri.includes('mobydigg.de'))) && (
                        <HybridLink
                          title={menuItem.label}
                          internal={menuItem.url}
                          type="internal"
                        />
                        ) || (
                        <HybridLink
                          title={menuItem.label}
                          external={menuItem.url}
                          type="external"
                        />
                        )}
                    </li>
                  ))
                }
              </ul>
            </nav>
            <nav className="socials-nav">
              <ul className="nav-list px-0 pb-1 pb-md-0 pb-lg-3">
                {
                  socialsMenu.map((menuItem) => (
                    <li key={menuItem.label} className="nav-item">
                      <HybridLink
                        title={menuItem.label}
                        external={menuItem.url}
                        type="external"
                      />
                    </li>
                  ))
                }
              </ul>
            </nav>
            <div className="brand-visual pt-md-1 pt-lg-0 pb-1 pb-lg-3">
              <MediaCard media={globalData.media} />
            </div>
          </div>
        </div>
      </footer>
    ) : null
  )
}

Footer.propTypes = {
  location: PropTypes.instanceOf(Object),
  locale: PropTypes.string,
  translations: PropTypes.instanceOf(Array)
}

export default Footer
