import React, { useEffect } from "react"
import {navigate} from "gatsby"
import PropTypes from "prop-types"
import { browserName } from "react-device-detect"
import MainNavigation from "Layout/Navigation/MainNavigation/MainNavigation"
import Footer from "Layout/Footer/Footer"
import SEO from "Partials/SEO/SEO"
import CookieNotice from "Partials/CookieNotice/CookieNotice"
import detectBrowserLanguage from 'detect-browser-language'

import "../../styles/style.scss"

const Layout = ({
  children,
  location,
  metaData,
  locale,
  translations,
  hideMenu
}) => {
  useEffect(() => {
    document.body.classList.add("is-touch-device")
    if(browserName === "Internet Explorer" ||  browserName === "Edge") document.body.classList.add("is-ie-or-edge-browser")
  })

  // useEffect(() => {
  //   const botPattern = "(googlebot\/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
  //   const re = new RegExp(botPattern, 'i');
  //   const userAgent = navigator.userAgent;
  //   if (!re.test(userAgent) && typeof window !== "undefined") {
  //     const userLang = detectBrowserLanguage();
  //     let mdCustomLang = localStorage.getItem("mdCustomLang")
  //         mdCustomLang = mdCustomLang && JSON.parse(mdCustomLang)
  //     let mdUserLang = localStorage.getItem("mdUserLang")
  //         mdUserLang = mdUserLang && JSON.parse(mdUserLang)

  //     // Navigate to English translation if the user is not German and sees a German site
  //     if (
  //       userLang !== "de-DE" &&
  //       window.location.pathname.indexOf("/en/") < 0 &&
  //       translations.length > 0 &&
  //       translations[0].uri &&
  //       !mdCustomLang
  //       ){
  //         navigate(translations[0].uri, {replace: true});
  //     }

  //     // Navigate to formerly set user lang if existent
  //     if (
  //       userLang !== "de-DE" &&
  //       window.location.pathname.indexOf("/en/") < 0 &&
  //       translations.length > 0 &&
  //       translations[0].uri &&
  //       !mdCustomLang
  //       ){
  //         navigate(translations[0].uri, {replace: true});
  //     }
  //   }

  // }, []);


  return (
    <div className="main-layout d-flex flex-column">
      <SEO metaData={metaData} location={location} locale={locale} translations={translations} />
      <MainNavigation hideMenu={hideMenu} location={location} locale={locale} translations={translations} />
      <div className="content-wrapper flex-grow-1 border-left">{children}</div>
      <Footer location={location} locale={locale} translations={translations} />
      <CookieNotice locale={locale} />
    </div>
  )
}

Layout.propTypes = {
  metaData: PropTypes.instanceOf(Object).isRequired,
  locale: PropTypes.string.isRequired,
  translations: PropTypes.instanceOf(Object).isRequired
}

export default Layout
