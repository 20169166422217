import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames'
import Collapsible from "react-collapsible"
import {RenderMediaLink} from "Partials/ViewLink/ViewLink"
import "./AccordionList.scss"

const AccordionList = ({ text, items }) => {
  const renderText = (item) => (
    <div>
      <div dangerouslySetInnerHTML={{__html: item.content.text}} />
      {RenderMediaLink(item)}
    </div>
  )

  // Include item of matching type
  const renderListItem = (item) => {
    switch(item.type) {
      case "isText":
        return renderText(item)
      case "isTextHalf":
        return renderText(item)
      default:
        return false
    }
  }

  return (
    items ? (
      <div className="section accordion-list-section border-left">
        <div className="container d-md-flex flex-wrap justify-md-content-end align-md-items-start">
          {text && (<div className="col-12 col-md-6" dangerouslySetInnerHTML={{__html: text}} />)}
          <div className={classNames(
            "accordion-list-container row",
            text && "col-12 col-md-6 mt-2 mt-md-0"
          )}>
            {
              items.map((item, index) => (
                <Collapsible
                  transitionTime={250}
                  easing="ease-in-out"
                  key={item.label}
                  tabIndex={index}
                  trigger={item.label}
                  open={item.open || false}
                  className={classNames(
                    "col-12 list-item-parent",
                    item.type === "isTextHalf" && "is-text-half col-md-6"
                  )}
                  openedClassName={classNames(
                    "col-12 list-item-parent is-open",
                    item.type === "isTextHalf" && "is-text-half col-md-6"
                  )}
                  triggerClassName="list-item-label"
                  triggerOpenedClassName="list-item-label-open"
                  contentOuterClassName="list-item-content-outer"
                  contentInnerClassName={classNames(
                    text && "list-item-content-inner has-text" || "list-item-content-inner"
                  )}
                >
                  {renderListItem(item)}
                </Collapsible>
              ))
            }
          </div>

        </div>
      </div>
    ) : null
  )
}

AccordionList.defaultProps = {
  items: {},
  text: null
}

AccordionList.propTypes = {
  items: PropTypes.instanceOf(Object),
  text: PropTypes.string
}

export default AccordionList
