import React, {forwardRef, useEffect, useRef} from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import {RenderMediaLink} from "Partials/ViewLink/ViewLink"
import TextNodesUnder from 'utils/text-nodes-under'
import HubSpotEmailForm from "Partials/HubSpotEmailForm/HubSpotEmailForm"
import "./TextGrid.scss"
import "../../Partials/Scrollytelling/Scrollytelling.scss"

const TextGrid = ({
  textGridType,
  texts,
  reducedMarginTop,
  scrollytelling,
  hubSpotEmailForm,
  locale,
  classes = ''
}) => {
  if(!texts?.length) {
    return null;
  }

  const activeColor = '#000';
  const color = '#cac6c6';
  const textGridRef = useRef(null);

  const changeColor = () => {
    const element = textGridRef?.current;
    if(!element) {
      return;
    }
    const height = typeof window !== "undefined" && window.innerHeight || 0;
    const halfH = height / 2;

    const textParents = TextNodesUnder(element);
    textParents.forEach(item => {
      const rect = item.getBoundingClientRect();
      const elemTop = rect.top;
      const elemHeight = rect.height;

      const activeArea = (halfH - 2 * elemHeight);
      if(
        elemTop > activeArea &&
        elemTop < halfH
        // item instanceof Node
      ) {
        item.style.color = activeColor;
      } else {
        item.style.color = color;
      }
    });
  }

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      changeColor();
    }, 100)
    window.addEventListener("scroll", changeColor);
    return () => {
      clearTimeout(currentTimeout);
      window.removeEventListener("scroll", changeColor);
    }
  }, [])

  return (
    <div className={
      classNames(
        "section text-grid-section",
        reducedMarginTop && "mt-0",
        classes
      )}
    >
      <div className="container">
        <div
          className={classNames("row",
            textGridType === "isSingleColumn" && "single-column" ||
            (textGridType === "isDoubleColumn" || textGridType === "isFourColumns") && "multi-column"
          )}
        >
          {texts?.map(textblock => (
            <div className={classNames("d-flex flex-column text-grid-item ml-auto",
            (textGridType === "isDoubleColumn") && "col-md-6" ||
            textGridType === "isFourColumns" && "col-sm-6 col-lg-3"
          )}>
            {scrollytelling && (
              <div className={classNames("text-scrollytelling")}>
                <div
                  ref={textGridRef}
                  className="text-grid-item__text text-scrollytelling__content"
                  key={textblock.text}
                  dangerouslySetInnerHTML={{__html: textblock.text}}
                />
                {hubSpotEmailForm && hubSpotEmailForm.hasHubspotEmailFormInput && hubSpotEmailForm?.portalid?.length > 0 && hubSpotEmailForm?.formid?.length > 0 && (
                  <HubSpotEmailForm locale={locale} portalId={hubSpotEmailForm.portalid} formId={hubSpotEmailForm.formid} />
                )}
              </div>
            ) || (
              <>
                <div
                  className="text-grid-item__text"
                  key={textblock.text}
                  dangerouslySetInnerHTML={{__html: textblock.text}}
                />
                {hubSpotEmailForm && hubSpotEmailForm.hasHubspotEmailFormInput && hubSpotEmailForm?.portalid?.length > 0 && hubSpotEmailForm?.formid?.length > 0 && (
                  <HubSpotEmailForm locale={locale} portalId={hubSpotEmailForm.portalid} formId={hubSpotEmailForm.formid} />
                )}
              </>
            )}
              {RenderMediaLink(textblock)}
            </div>))}
        </div>
      </div>
    </div>
  )
}

TextGrid.propTypes = {
  textGridType: PropTypes.string.isRequired,
  texts: PropTypes.instanceOf(Object),
  classes: PropTypes.string,
  reducedMarginTop: PropTypes.bool,
  scrollytelling: PropTypes.bool,
}

export default forwardRef((props, ref) => (
  <TextGrid ref={ref} {...props} />
));
