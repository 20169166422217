import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Swiper from "react-id-swiper"
import { Autoplay } from 'swiper';
import MediaCard from "Partials/MediaCard/MediaCard"
import "./MediaSlider.scss"

const MediaSlider = ({
  media,
  maxWidthLayout,
  slidesPerView,
  autoPlay,
  hideControls,
  title,
  reducedMarginTop,
  wideLayout,
}) => {
  const ref = useRef(null)
  const slides = media
  const [activeSlideIndex, setActiveSlideIndex] = useState(1)

  // Swiper pagination controls
  const goNext = () => {
    if (!!ref?.current?.swiper) {
      ref.current.swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (!!ref?.current?.swiper) {
      ref.current.swiper.slidePrev()
    }
  }

  useEffect(() => {
    if (!!ref?.current?.swiper) {
      ref?.current?.swiper?.update()
      ref.current.swiper.on('slideChange', () => {
        if(!!ref?.current?.swiper) {
          setActiveSlideIndex(ref.current.swiper.activeIndex+1)
        }
      })
    }
  })

  const params = {
    modules: [Autoplay],
    slidesPerView: (slidesPerView > 4 && 4) || slidesPerView || 1,
    autoplay: autoPlay && {
      delay: 3000,
      disableOnInteraction: false
    },
    grabCursor: false,
    simulateTouch: !!(slides?.length > 1),
    activeSlideKey: 0,
    loopedSlides: slides?.length * 2,
    spaceBetween: wideLayout ? 20 : 5,
    pagination: {
      el: '.swiper-pagination'
    },
    breakpoints: {
      768: {
        spaceBetween:  wideLayout ? 40 : 10,
        slidesPerView: (slidesPerView > 6 && 6) || slidesPerView || 1,
      },
      1240: {
        noSwiping: true,
        spaceBetween:  wideLayout ? 100 : 20,
        slidesPerView: slidesPerView || 1,
      }
    }
  }

  const navButtons = <>
    <div
      className={classNames("swiper-btn-prev", activeSlideIndex === 1 && "is-hidden")}
      onClick={goPrev}
      role="button"
      tabIndex="0"
    />
    <div
      className={classNames("swiper-btn-next", activeSlideIndex === slides?.length && "is-hidden")}
      onClick={goNext}
      role="button"
      tabIndex="-1"
    />
  </>

  const renderActiveSliderMediaCaption = () => {
    if(activeSlideIndex < 1 || !slides?.length || slides?.length < activeSlideIndex ) {
      return null;
    }
    const {caption, subcaption} = slides[activeSlideIndex - 1];
    if(!caption && !subcaption) {
      return null;
    }

    return <div className="media-caption">
      {caption && (
        <figcaption
          className="flex-grow-1"
          dangerouslySetInnerHTML={{__html: caption}}
        />
      )}
      {subcaption && (
        <span
          className="figcaption"
          dangerouslySetInnerHTML={{__html: subcaption}}
        />
      )}
    </div>
  }

  return slides ? (
    <div
      className={classNames(
        "section media-slider-section border-left",
        maxWidthLayout && "max-width-layout",
        reducedMarginTop && "mt-1"
      )}
    >
      <div className="container">
        <div className="slider-wrapper">
          <Swiper {...params} ref={ref}>
            {
              slides?.length > 0 && slides.map((slide, index) => (slide.image || slide.video) && (
                <div
                  className={classNames("slide", slide.image?.localFile?.childImageSharp?.gatsbyImageData?.aspectRatio > 1 ? "landscape" : "portrait")}
                  key={`${slide.type}--${slide.image?.sourceUrl}--${index}`}
                >
                  <MediaCard media={slide} classes={ slides?.length > 1 ? 'swiper-content' : '' } />
                </div>
              ))
            }
          </Swiper>
          {(slides?.length > 1 && !hideControls) ? (
            <>
              <div className="swiper-navigation position-absolute d-flex justify-content-between swiper-navigation--main-navigation">
                {navButtons}
              </div>
              <div className="swiper-description d-flex justify-content-between">
                <span className={classNames("swiper-pagination position-relative d-flex", (title && title?.length > 0) && "grey")}>
                  {(slidesPerView === 1 && (!title || title?.length === 0)) && (
                    <>
                      {activeSlideIndex}/{slides?.length}
                    </>
                  ) || title?.length > 0 && (
                    <>
                      {title}
                    </>
                  )|| ""}
                </span>
                { renderActiveSliderMediaCaption() }
                <div className="swiper-navigation swiper-navigation--sub-navigation d-flex justify-content-between">
                  {navButtons}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
}

MediaSlider.defaultProps = {
  media: {},
  slidesPerView: 1,
  wideLayout: false,
  title: "",
}

MediaSlider.propTypes = {
  media: PropTypes.instanceOf(Object).isRequired,
  slidesPerView: PropTypes.number.isRequired,
  autoPlay: PropTypes.bool.isRequired,
  hideControls: PropTypes.bool.isRequired,
  reducedMarginTop: PropTypes.bool,
  wideLayout: PropTypes.bool,
  title: PropTypes.string,
}

export default MediaSlider
