import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Text from "Partials/Text/Text"
import PostCard from "Partials/PostCard/PostCard"
import "./ComparisonCard.scss"

const ComparisonCard = ({ classes = '', criterias = [], features = [], localeProjects = [] }) => {

  const [matchesDesktop, setMatchesDesktop] = useState(false);
  const [currentFeatures, setCurrentFeatures] = useState([]);
  const [currentCriterias, setCurrentCriterias] = useState([]);

  const updateMatchesDesktop = e => setMatchesDesktop(e.matches);

  useEffect(() => {
    setMatchesDesktop(window.matchMedia("(max-width: 1240px)").matches); // $desktop

    window
    .matchMedia("(max-width: 1240px)") // $desktop
    .addEventListener('change', updateMatchesDesktop);

    return () => {
      window
      .matchMedia("(max-width: 1240px)") // $desktop
      .removeEventListener('change', updateMatchesDesktop);
    }
  }, []);

  useEffect(() => {
    const localCriterias = criterias || [];
    const criteriaKeys = localCriterias.map(({key}) => key);
    const localFeatures = (features || []).map((feature) => {
      const selectedCriteriaKey = feature.selectedCriteriaKey || (criteriaKeys?.length > 0 ? criteriaKeys[0] : undefined)
      return {
        ...feature,
        selectedCriteriaKey
      }
    });

    setCurrentCriterias(localCriterias)
    setCurrentFeatures(localFeatures);
  }, [features, criterias]);


  const renderProject = (project) => {
    if(!project) {
      return null;
    }
    const findProject =  localeProjects.find(localProject => localProject?.node?.id === project?.id);
    if(!findProject) {
      return null;
    }
    return (
      <div className="row post-items-grid">
        <div className="post-grid-item single-column">
          <PostCard
            image={findProject?.node?.featuredImage?.node || null}
            caption={findProject.node.title}
            subCaption={findProject.node.excerpt}
            linkTarget={findProject.node.uri}
            aspectRatios={[1.32, 0.667]}
          />
        </div>
      </div>
      )
  }

  return (
    <div className={classNames("comparison-card", classes)}>
      { !matchesDesktop && <>
        {[null, ...currentFeatures].map((feature, featureIndex) => {
          if(featureIndex === 0) {
            return <div key={featureIndex} className="comparison-card__header">
              <div className="comparison-card__cell feature-name" />
              {currentCriterias.map(({key, description}) => <div
                  key={key + featureIndex}
                  className="comparison-card__cell">
                    <Text type="h1" text={description} />
              </div>)}
            </div>;
          }
          const {name, terms = []} = feature;

          const featureName = <div
            key={featureIndex}
            className="comparison-card__cell feature-name">
              <Text text={name}/>
            </div>;

          const featureTerms = currentCriterias.map(({key}, criteriaIndex) => {
            const termIndex = terms.findIndex(term => term.criteriaKey === key);
            if(termIndex === -1) {
              return <div
                className="comparison-card__cell term"
                key={featureIndex + key + criteriaIndex}>
                  <div className="term__content">
                    <Text text={''}/>
                  </div>
              </div>
            }
            const {criteriaKey, content, project} = terms[termIndex];

            return <div key={featureIndex + criteriaKey + criteriaIndex} className="comparison-card__cell term">
              <div className="term__content">
                <Text isHtml={true} text={content || ''}/>
              </div>
              {renderProject(project)}
            </div>
          });

          return <div className="comparison-card__row">
            {featureName}
            {featureTerms}
          </div>
        })}
      </>}
      {
        matchesDesktop && <>
        { currentFeatures.map(({name, terms = [], selectedCriteriaKey}, featureIndex) => {

          const featureName = <div
            className="comparison-card__cell feature-name">
              <Text text={name}/>
            </div>;
          const featureCriterias = currentCriterias.map(({key, description}, criteriaIndex) => <div
            className={classNames("comparison-card__feature-criteria", selectedCriteriaKey === key ? 'comparison-card__feature-criteria--active': null)}
            onClick={() => {
              const initFeatures = currentFeatures;
              const currentFeature = initFeatures[featureIndex];
              currentFeature && (currentFeature.selectedCriteriaKey = key);
              setCurrentFeatures([...initFeatures]);
            }}
            key={key + criteriaIndex + featureIndex}>
            <Text text={description}/>
          </div>);

          const selectedFeatureTerm = terms.find(({criteriaKey}) => criteriaKey === selectedCriteriaKey);

          let featureTerm = <div
            className="comparison-card__cell term"
            key={featureIndex}>
              <div className="term__content">
                <Text text={''}/>
              </div>
          </div>;
          if(!!selectedFeatureTerm) {
            const {content, project} = selectedFeatureTerm;
            featureTerm = <div className="comparison-card__cell term">
              <div className="term__content">
                <Text isHtml={true} text={content || ''}/>
              </div>
              {renderProject(project)}
            </div>
          }
          return <div key={featureIndex} className="comparison-card__row">
            {featureName}
            <div className="comparison-card__criterias">
              {featureCriterias}
            </div>
            {featureTerm}
          </div>
        })}</>
      }
    </div>
  )
}

ComparisonCard.propTypes = {
  criterias: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ),
  features: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      terms: PropTypes.arrayOf(
        PropTypes.shape({
          criteriaKey: PropTypes.string.isRequired,
          content: PropTypes.string,
          project: PropTypes.instanceOf(Object)
        })
      )
    })
  ),
  classes: PropTypes.string,
  localeProjects: PropTypes.instanceOf(Array)
}

export default ComparisonCard
