import React, {forwardRef} from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import MediaCard from "Partials/MediaCard/MediaCard"
import "./MediaGrid.scss"

const MediaGrid = ({
  media,
  mediaGridType,
  maxWidthLayout,
  reducedMarginTop,
  locale,
  classes = ''
}) => {
  return (
    media ? (
      <div
        className={
          classNames(
            "section media-grid-section",
            reducedMarginTop && "mt-0",
            maxWidthLayout && "max-width-layout",
            mediaGridType !== "isDoubleColumn" && 'border-left',
            classes
          )}
      >
        <div className="container">
          <div className="row">
            {media.map(mediaItem => (
              <>
                {mediaItem && (
                  <div
                    key={mediaItem?.image?.id || mediaItem}
                    className={classNames("media-grid-item",
                      mediaItem.maxHeight && "max-height-set",
                      mediaGridType === "isSingleColumn" && "single-column" ||
                      mediaGridType === "isDoubleColumn" && "double-column" ||
                      mediaGridType === "isFourfoldColumn" && "fourfold-column" ||
                      mediaGridType === "isSixfoldColumn" && "sixfold-column",
                      (!mediaItem.caption && !mediaItem.subcaption) && "no-text"
                    )}
                  >
                    <MediaCard
                      media={mediaItem}
                      aspectRatios={mediaItem.keepRatio && "KEEPRATIO" || mediaGridType === "isFourfoldColumn" && [1.334]}
                      canZoom={mediaGridType !== "isFourfoldColumn" && mediaGridType !== "isSixfoldColumn"}
                      locale={locale}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    ) : null
  )
}

MediaGrid.propTypes = {
  media: PropTypes.instanceOf(Object).isRequired,
  mediaGridType: PropTypes.string,
  maxWidthLayout: PropTypes.bool,
  reducedMarginTop: PropTypes.bool,
  classes: PropTypes.string,
}

export default forwardRef((props, ref) => (
  <MediaGrid ref={ref} {...props} />
));
