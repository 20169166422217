import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import classNames from "classnames"
import Image from "Partials/Image/Image"
import "./PostCard.scss"

const PostCard = ({ image, caption, subCaption, linkTarget, classes, aspectRatios }) => {
  return (
    <Link to={linkTarget} className={classNames("post-card", classes)}>
      <figure className="d-flex flex-column">
        {
          image && (
            <Image
              image={image}
              aspectRatios={aspectRatios}
              cropImage
            />
          )
        }
        {
          caption && (
            <figcaption className="flex-grow-1">
              {caption}
            </figcaption>
          )
        }
        {
          subCaption && (
            <span
              className="figcaption"
              dangerouslySetInnerHTML={{__html: subCaption}}
            />
          )
        }
      </figure>
    </Link>
  )
}

PostCard.propTypes = {
  caption: PropTypes.string,
  classes: PropTypes.string,
  linkTarget: PropTypes.string,
  image: PropTypes.instanceOf(Object)
}

export default PostCard
