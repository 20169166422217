import React, {useRef, useEffect} from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import TextGrid from "Sections/TextGrid/TextGrid"
import MediaGrid from "Sections/MediaGrid/MediaGrid"
import "./MediaGridScrollytelling.scss"
import TextNodesUnder from 'utils/text-nodes-under'

const MediaGridScrollytelling = ({ caption, classes = '' }) => {
  const textGridCaptionProps = {
    texts: caption?.text ? [{text: caption?.text}] : [],
    textGridType: 'isSingleColumn',
  };

  const mediaGridRef = useRef(null);

  const changeElementOpacity = (element) => {
    if(!element) {
      return;
    }
    const height = window.innerHeight;
    const rect = element.getBoundingClientRect();
    const elemTop = rect.top;
    const elemHeight = rect.height;

    // Not visiable
    if(elemTop + elemHeight < 0 || elemTop > height) {
      element.style.opacity = 0;
      return;
    }

    // On top
    const startTopRange = -elemHeight;
    const endTopRange = 0;
    if(elemTop > startTopRange && elemTop < endTopRange) {
      if(elemTop > startTopRange / 2) {
        element.style.opacity = 0.5;
      } else {
        element.style.opacity = 0.18;
      }
      return;
    }
    // On bottom
    const startBottomRange = height - elemHeight;
    const endBottomRange = height;
    if(elemTop > startBottomRange && elemTop < endBottomRange) {
      if(elemTop < ((startBottomRange + endBottomRange)/2) ) {
        element.style.opacity = 0.5;
      } else {
        element.style.opacity = 0.18;
      }
      return;
    }
    // Whole visiable
    element.style.opacity = 1;
  }

  const changeTextGridAnimation = () => {
    const element = mediaGridRef?.current?.base;
    changeElementOpacity(element);
  }

  const changeMediaGridAnimation = () => {
    const element = mediaGridRef?.current?.base;
    if(!element) {
      return;
    }
    const mediaCardDescriptions = Array.from(element.getElementsByClassName('media-card__description') || []);

    mediaCardDescriptions.forEach(item => {
      changeElementOpacity(item);
    });
  }

  const changeTextGridCaptionAnimation = (distance) => {
    const element = mediaGridRef?.current?.base;
    changeElementOpacity(element);

    if(!element) {
      return;
    }
    const wrapperItems = Array.from(element.getElementsByClassName('text-grid-item__text'));
    const wrapper = wrapperItems.length > 0 ? wrapperItems[0] : null;
    if(!wrapper) {
      return;
    }

    const textGridItems = TextNodesUnder(element);
    const pageHeight = window.innerHeight;
    const wrapperTop = wrapper.getBoundingClientRect().top;

    const startRange = pageHeight / 4;
    const endRange = pageHeight * (3/4);
    const stepSize = (endRange - startRange) / 40;
    const ranges = (new Array(40)).fill(null).map((item, index) => startRange + index*stepSize);

    let currentOffset = 0;
    if(wrapperTop >= startRange && wrapperTop <= endRange) {
      const rangeIndex = ranges.findIndex(value => value > wrapperTop);
      currentOffset = rangeIndex > -1 ? 2 * (40 - rangeIndex) : 0;
    } else if(wrapperTop < startRange){
      currentOffset = 80;
    }

    textGridItems.forEach((item, index) => {
      const isOdd = (index + 1) % 2 === 1;
      if(isOdd) {
        let calc = currentOffset;
        item.style.left = `${calc}px`;
      } else {
        let calc = -currentOffset;
        item.style.left = `${calc}px`;
      }
    });
  }

  const checkScrolling = () => {
    changeTextGridAnimation();
    changeMediaGridAnimation();
  }

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      checkScrolling();
    }, 1000)
    window.addEventListener("scroll", checkScrolling);
    return () => {
      clearTimeout(currentTimeout);
      window.removeEventListener("scroll", checkScrolling);
    }
  }, []);

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      changeTextGridCaptionAnimation(0);
    }, 1000);

    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDistance = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }

      changeTextGridCaptionAnimation(scrollY - lastScrollY);
      lastScrollY = scrollY
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDistance)
        ticking = true
      }
    }

    window.addEventListener("scroll", onScroll)
    return function cleanup() {
      clearTimeout(currentTimeout);
      window.removeEventListener("scroll", onScroll)
    }
  }, []);


  return (<div className={classNames('media-grid-scrollytelling', classes)}>
    <TextGrid
      ref={mediaGridRef}
      classes={classNames('media-grid-scrollytelling__text-grid--caption',
        caption?.alignmentType === 'isTextLeft' && 'caption--left',
        caption?.alignmentType === 'isTextCenter' && 'caption--center'
      )}
      {...textGridCaptionProps}/>
  </div>)
}

MediaGridScrollytelling.propTypes = {
  caption: PropTypes.shape({
    text: PropTypes.string,
    alignmentType: PropTypes.string,
  }),
  classes: PropTypes.string,
}

export default MediaGridScrollytelling
