import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import TextGrid from "Sections/TextGrid/TextGrid"
import "./HeroOpenerImage.scss"

const HeroOpenerImage = ({
  texts,
  backgroundImage,
  backgroundColor,
  classes = ''
}) => {
  texts = texts || [];
  backgroundImage = backgroundImage || undefined;
  backgroundColor = backgroundColor || '#000';
  classes = classes || '';

  const textGridRef = useRef(null);

  const textGridProps = {
    textGridType: 'isSingleColumn',
    texts,
  }

  const changeTextGridOpacity = () => {
    const element = textGridRef?.current?.base;
    if(!element) {
      return;
    }
    const height = window.innerHeight;

    const rect = element.getBoundingClientRect();
    const elemTop = rect.top;
    const elemHeight = rect.height;

    // Not visiable
    if(elemTop + elemHeight < 0 || elemTop > height) {
      element.style.opacity = 0;
      return;
    }

    // On top
    const startTopRange = -elemHeight;
    const endTopRange = 0;
    if(elemTop > startTopRange && elemTop < endTopRange) {
      if(elemTop > startTopRange / 2) {
        element.style.opacity = 0.5;
      } else {
        element.style.opacity = 0.18;
      }
      return;
    }
    // On bottom
    const startBottomRange = height - elemHeight;
    const endBottomRange = height;
    if(elemTop > startBottomRange && elemTop < endBottomRange) {
      if(elemTop < ((startBottomRange + endBottomRange)/2) ) {
        element.style.opacity = 0.5;
      } else {
        element.style.opacity = 0.18;
      }
      return;
    }
    // Whole visiable
    element.style.opacity = 1;
  }

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      changeTextGridOpacity();
    }, 1000)
    window.addEventListener("scroll", changeTextGridOpacity);
    return () => {
      clearTimeout(currentTimeout);
      window.removeEventListener("scroll", changeTextGridOpacity);
    }
  }, [])

  return (
    <div
      className={classNames("hero-opener-image", classes)}
      style={{
        backgroundImage: backgroundImage?.sourceUrl ? `url(${backgroundImage?.sourceUrl})`: 'none',
        backgroundColor
      }}>
      <TextGrid ref={textGridRef} {...textGridProps} classes="hero-opener-image__text-grid" />
    </div>
  )
}

HeroOpenerImage.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string,
    buttonLink: PropTypes.instanceOf(Object),
    commonLink: PropTypes.instanceOf(Object),
  }),
  backgroundImage: PropTypes.shape({
    id: PropTypes.string,
    sourceUrl: PropTypes.string
  }),
  backgroundColor: PropTypes.string,
}

export default HeroOpenerImage
