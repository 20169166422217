import React, {forwardRef} from "react"
import PropTypes from "prop-types"
import HubspotForm from 'react-hubspot-form'
import classNames from "classnames"
import "./HubSpotForm.scss"

const HubSpotForm = ({ title, jumpToId, portalId, formId, classes = '' }) => {
  return (
    (portalId && formId) ? (
      <div id={jumpToId} className="pt-2">
        <div
          className={classNames(
            "section hub-spot-form-section border-left pt-0",
            classes
          )}
        >
          <div className="container d-flex">
            <div className="content-part mb-2" dangerouslySetInnerHTML={{__html: title}} />
            <div className="content-part form-wrap">
              <HubspotForm
                portalId={portalId}
                formId={formId}
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </div>
      </div>
    ) : null
  )
}

HubSpotForm.propTypes = {
  jumpToId: PropTypes.string,
  portalId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  classes: PropTypes.string,
}

export default forwardRef((props, ref) => (
  <HubSpotForm ref={ref} {...props} />
));
