import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './ViewLink.scss'

const ViewLink = props => {
  const {
    title = 'Visit site',
    url,
    target = '_self',

    classes = '',
    type = 'link', // 'link' | 'button'
    children,
  } = props;

  if(!url || !title) return null;

  const linkClick = (e) => {
    e?.stopPropagation();
  }

  switch (type) {
    case 'button':
      return <a onClick={linkClick} className={classnames(classes, 'view-link view-link--button')} href={url} target={target || '_self'} rel="noopener noreferrer">
        <span className='view-link__title'>{title}</span>
        {children}
      </a>
    case 'link':
    default:
      return <a onClick={linkClick} className={classnames(classes, 'view-link view-link--common')} href={url} target={target || '_self'} rel="noopener noreferrer">
        <span className='view-link__title'>{title}&nbsp;&#8599;</span>
        {children}
      </a>
  }
}

ViewLink.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
  classes: PropTypes.string,
  target: PropTypes.string,
}

export const RenderMediaLink = (media) => {
  if(!media) {
    return null
  }
  const {commonLink, buttonLink} = media
  return <>{(commonLink || buttonLink) && (
    <div className="media-link-wrapper">
      {commonLink && (
        <ViewLink {...commonLink}/>
      )}
      {buttonLink && (
        <ViewLink
          type='button'
          {...buttonLink}
        />
      )}
    </div>
  )}</>
}

export default ViewLink;
