import React  from 'react'
import { Helmet } from 'react-helmet-async';

import appleTouchIcon from '../../../../static/assets/favicons/apple-touch-icon.png';
import favicon32 from '../../../../static/assets/favicons/favicon-32x32.png';
import favicon16 from '../../../../static/assets/favicons/favicon-16x16.png';
import safariPinnedTab from '../../../../static/assets/favicons/safari-pinned-tab.png';

const SEO = (props) => {
  const { metaData, location, locale, translations } = props;

  const titleize = (slug) => {
    const words = slug.split("-");
      return words.map((word) => {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
      }).join(' ');
  }

  const getLocalTitle = () => {
    const pathPrefix = "/";
    const currentPath = location.pathname
    .replace(pathPrefix, "")
    .split("/").pop()

    const isHome = currentPath === ""
    const title = isHome ? "Home" : titleize(currentPath)

    return title;
  }

  const suffix = `| Moby Digg`

  const metaDataUpdated = {
    metaInfoTitle: metaData && metaData.metaInfoTitle && metaData.metaInfoTitle.indexOf('| Moby Digg') === -1 && `${metaData.metaInfoTitle} ${suffix}` || metaData && metaData.metaInfoTitle && `${metaData.metaInfoTitle}` || `${getLocalTitle()} ${suffix}`,
    metaInfoDescription: metaData && metaData.metaInfoDescription || "",
    metaInfoKeywords: metaData && metaData.metaInfoKeywords || "",
    metaInfoShareImage: metaData && metaData.metaInfoShareImage && metaData.metaInfoShareImage.sourceUrl || "",
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: locale === "de_DE" && "de" || "en"
      }}
      meta={[
        {
          name: `title`,
          content: metaDataUpdated.metaInfoTitle,
        },
        {
          name: `description`,
          content: metaDataUpdated.metaInfoDescription,
        },
        {
          name: `keywords`,
          content: metaDataUpdated.metaInfoKeywords
        },
        {
          name: `image`,
          content: metaDataUpdated.metaInfoShareImage,
        },
        {
          property: `og:url`,
          content: location.href,
        },
        {
          property: `og:title`,
          content: metaDataUpdated.metaInfoTitle,
        },
        {
          property: `og:description`,
          content: metaDataUpdated.metaInfoDescription,
        },
        {
          property: `og:image`,
          content: metaDataUpdated.metaInfoShareImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: `@mobydigg`,
        },
        {
          name: `twitter:title`,
          content: metaDataUpdated.metaInfoTitle,
        },
        {
          name: `twitter:description`,
          content: metaDataUpdated.metaInfoDescription,
        },
        {
          name: `twitter:image`,
          content: metaDataUpdated.metaInfoShareImage,
        },
        {
          name: `twitter:url`,
          content: location.href,
        },
        {
          name: `application-name`,
          content: metaDataUpdated.metaInfoTitle,
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: `black-translucent`,
        },
        {
          name: `apple-mobile-web-app-title`,
          content: metaDataUpdated.metaInfoTitle,
        },
      ]}
    >
      <title>{metaDataUpdated.metaInfoTitle}</title>
      <link rel="apple-touch-icon" sizes="128x128" href={appleTouchIcon} />
      <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
      <link rel="icon" type="image/png" href={favicon16} sizes="16x16" />
      <link rel="mask-icon" href={safariPinnedTab} />
      <link rel="alternate" hreflang={location.pathname.indexOf("/en/") < 0 && "de" || "en"} href={`https://mobydigg.de${location.pathname}`} />
      {translations?.length > 0 && translations.map((translation) => (
        <link rel="alternate" hreflang={translation.language.slug} href={`https://mobydigg.de${translation.uri}`} />
      ))}
      {/* SalesViewer */}
      <script defer="true" type="text/javascript">
        {`
          (function(w,d,s,l,i){
              var g=w[l] ? '&s='+w[l]:'', f=d.getElementsByTagName(s)[0], j=d.createElement(s);
              j.async=true,j.src='https://svrdntfctn.com/stm.js?id='+i+g;
              j.referrerPolicy='no-referrer-when-downgrade';
              f.parentNode.insertBefore(j,f);
          })(window,document,'script','name','Y5c9z8u5Z3L9')
        `}
      </script>
    </Helmet>
  );
}

export default SEO;
