import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./Text.scss"

const Text = ({ text, type, isHtml, classes }) => {
  let returnEl = null

  if(text){
    // strip html elements
    const htmlRendered = !isHtml ? text.replace(/(<([^>]+)>)/ig,"") : text

    if(type === "pageTitle" && !isHtml) returnEl = (<h1>{htmlRendered}</h1>)
    if(type === "h1" && !isHtml) returnEl = (<h2 className={classNames(classes, "h1")}>{htmlRendered}</h2>)
    if(type === "h2" && !isHtml) returnEl = (<h2 className={classNames(classes)}>{htmlRendered}</h2>)
    if(type === "h3" && !isHtml) returnEl = (<h3 className={classNames(classes)}>{htmlRendered}</h3>)
    if(type === "h4" && !isHtml) returnEl = (<h4 className={classNames(classes)}>{htmlRendered}</h4>)
    if(type === "h5" && !isHtml) returnEl = (<h5 className={classNames(classes)}>{htmlRendered}</h5>)
    if(type === "h6" && !isHtml) returnEl = (<h6 className={classNames(classes)}>{htmlRendered}</h6>)
    if(type === "p"  && !isHtml) returnEl = (<p className={classNames(classes)}>{htmlRendered}</p>)

    if(type === "pageTitle" &&  isHtml) returnEl = (<h1 dangerouslySetInnerHTML={{__html: htmlRendered}} />)
    if(type === "h1" &&  isHtml) returnEl = (<h2 className={classNames(classes, "h1")} dangerouslySetInnerHTML={{__html: htmlRendered}} />)
    if(type === "h2" &&  isHtml) returnEl = (<h2 className={classNames(classes)} dangerouslySetInnerHTML={{__html: htmlRendered}} />)
    if(type === "h3" &&  isHtml) returnEl = (<h3 className={classNames(classes)} dangerouslySetInnerHTML={{__html: htmlRendered}} />)
    if(type === "h4" &&  isHtml) returnEl = (<h4 className={classNames(classes)} dangerouslySetInnerHTML={{__html: htmlRendered}} />)
    if(type === "h5" &&  isHtml) returnEl = (<h5 className={classNames(classes)} dangerouslySetInnerHTML={{__html: htmlRendered}} />)
    if(type === "h6" &&  isHtml) returnEl = (<h6 className={classNames(classes)} dangerouslySetInnerHTML={{__html: htmlRendered}} />)
    if(type === "p"  &&  isHtml) returnEl = (<p className={classNames(classes)} dangerouslySetInnerHTML={{__html: htmlRendered}} />)
  }

  return returnEl
}

Text.defaultProps = {
  type: "p",
  isHtml: false
}
Text.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  isHtml: PropTypes.bool,
  classes: PropTypes.string
}

export default Text
