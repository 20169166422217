import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import "./Video.scss"

const Video = ({ src, poster, hasControls, classes }) => {
  const videoEl = useRef(null)
  const [isPaused, setIsPaused] = useState(hasControls)
  const [isMuted, setIsMuted] = useState(true)
  const [isActive, setIsActive] = useState(false)

  const togglePlay = () => {
    isPaused ? videoEl.current.play() : videoEl.current.pause();
    setIsActive(true);
    setIsPaused(!isPaused);
  }

  const toggleMute = () => {
    isMuted ? videoEl.current.muted=false : videoEl.current.muted=true;
    setIsMuted(!isMuted);
  }

  useEffect(() => {
    if (!hasControls) {
      const playPromise = videoEl.current.play();

      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Automatic playback started!
          // Show playing UI.
          // We can now safely pause video...
        })
        .catch(error => {
          // Auto-play was prevented
          /* eslint-disable-next-line */
          console.log(error)
        });
      }
    }
  })

  return (
    <div className={classnames("video-container", classes)}>
      <video
        ref={videoEl}
        src={src}
        poster={poster}
        autoPlay={!hasControls}
        playsInline
        loop
        muted
      />
      {hasControls && (
        <div className="video-controls">
          <div
            className="btn btn-play d-inline-block"
            onClick={togglePlay}
            role="button"
            tabIndex="0"
          >
            {isPaused ? 'Play' : 'Pause'}
          </div>
          {isActive && (
            <div
              className="btn btn-mute d-inline-block"
              onClick={toggleMute}
              role="button"
              tabIndex="1"
            >
              {isMuted ? 'Unmute' : 'Mute'}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

Video.defaultProps = {
  hasControls: false
}

Video.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  hasControls: PropTypes.bool,
  classes: PropTypes.string
}

export default Video
