import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import CookieConsent from "react-cookie-consent"
import "./CookieNotice.scss"

const CookieNotice = ({ locale }) => {
  const data = useStaticQuery(graphql`
    query CookieNoticeQuery {
      wp {
        globalDataDe {
          cookieNoticeData {
            text
          }
        }
        globalDataEn {
          cookieNoticeData {
            text
          }
        }
      }
    }
  `)

  const [cookiePreference, setCookiePreference] = useState(null)
  const { globalDataDe, globalDataEn } = data.wp

  const handleCookiePreference = (value) => {
    setCookiePreference(value)

    if(value && (typeof window !== "undefined")) {
      window.trackGoogleAnalytics()
      window.trackFbPixel()
      window.trackHotjar()
      window.trackGoogleAds()
    }
  }

  return (
    <CookieConsent
      cookieName="MDCookieConsent"
      buttonText={locale === "de_DE" && "Akzeptieren" || locale === "en_GB" && "Accept"}
      declineButtonText={locale === "de_DE" && "Ablehnen" || locale === "en_GB" && "Decline"}
      location="none"
      overlay={true}
      overlayClasses="overlay-wrapper"
      disableStyles
      disableButtonStyles
      enableDeclineButton
      flipButtons
      setDeclineCookie
      // hideOnAccept={false}
      // hideOnDecline={false}
      containerClasses="cookie-notice-wrapper"
      contentClasses="cookie-notice-inner d-flex align-items-center mr-1 mr-md-0 flex-grow-1"
      buttonWrapperClasses="button-wrapper pl-1 pr-1 pb-1 d-flex mt-1 mt-md-0"
      // buttonClasses="button-wrapper d-flex mt-1 mt-md-0 justify-content-between"
      declineButtonClasses="ml-1"
      onAccept={() => handleCookiePreference(true)}
      onDecline={() => handleCookiePreference(false)}
      expires={90}
    >
      <div className={classNames("d-flex flex-column flex-md-row justify-content-between p-1 bg-sand", cookiePreference !== null ? "is-hidden" : "")}>
        <div
          className="cookie-notice-content"
          dangerouslySetInnerHTML={{ __html:
            locale === "de_DE" && globalDataDe.cookieNoticeData.text ||
            locale === "en_GB" && globalDataEn.cookieNoticeData.text
          }}
        />
      </div>
    </CookieConsent>
  )
}

export default CookieNotice
